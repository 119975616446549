jQuery(document).ready(function($) {
    if ( $('.category-intro__content').length > 0 ) {
        $('.category-intro__content').each(function(){
            if ( $('.category-intro__content > *' ).length > 1) {
                $(this).children().first().after(' <a href="javascript:void(0);" class="read-more">Read More +</a>');
                
                $('a.read-more').on('click',function(e){
                    e.preventDefault();
                    $(this).siblings().show();
                    $(this).remove();
                });
            }
        });
    }
});