jQuery(function ($) {
  $('#filterNewsPosts').on('submit', function () {
    var filter = $('#filterNewsPosts');
    $.ajax({
      url: filter.attr('action'),
      data: filter.serialize(), // form data
      type: filter.attr('method'), // POST
      action: 'filter_posts',

      success: function (data) {
        $('#loader').html(data); // insert data
        $('#page-navigation').hide();
      },
    });
    return false;
  });

  $('#filterNewsPosts').on('change', function () {
    $(this).closest('form').submit();
  });
});
