jQuery(function($) {
	
	var application_guides = {

		load_guides_request : false,
		load_guides_timeout : false,

		init : function() {
			let that = this;

			$('div[data-application="application-guides"]').on('load-guides', function() {
				that.load_guides(this);
			});

			$('div[data-application="application-guides"]').on('keyup', '.input-search', function() {
				$(this).closest('div[data-application="application-guides"]').attr('data-next', 0);
				that.load_guides($(this).closest('div[data-application="application-guides"]')[0]);
			});

			$('div[data-application="application-guides"]').on('click', '.load-more', function() {
				$(this).prop('disabled', true);
				that.load_guides($(this).closest('div[data-application="application-guides"]')[0], {
					'load_more' : true
				});
			});

			$('div[data-application="application-guides"]').on('change', '.type-filter', function() {
				$(this).closest('div[data-application="application-guides"]').attr('data-next', 0);
				that.load_guides($(this).closest('div[data-application="application-guides"]')[0]);
			});
		},

		load_guides : function(obj, args) {
			let that     = this;
			let category = $(obj).attr('data-category');
			let start    = $(obj).attr('data-next');
			let search   = $(obj).find('.input-search').val();
			let type     = $(obj).find('.type-filter').val();

			let no_results_text = $(obj).attr('data-no-results-text');
			let shown_all_text  = $(obj).attr('data-shown-all-text');

			if(typeof args == 'undefined') {
				var args = {};
			}

			if( ! args.load_more)
			{
				$(obj).find('.application-guides__load-more-wrapper').addClass('hide');
				$(obj).find('.results').html('');
			}

			that.loading(obj);

			if(that.load_guides_timeout) {
				clearTimeout(that.load_guides_timeout);
			}

			if(type) {
				$(obj).find('.category-shown').text($(obj).find('.type-filter option:selected').text());
			} else {
				$(obj).find('.category-shown').text(shown_all_text);
			}

			that.load_guides_timeout = setTimeout(function() {
				if(that.load_guides_request) {
					that.load_guides_request.abort();
				}

				let request = {
					'action'   : 'clar_resource_hub_load_guides',
					'category' : category,
					'start'    : start,
					'search'   : search,
					'type'     : type
				};
				that.load_guides_request = $.get(ajaxurl, request, function(response) {
					let html = '';

					if(response.guides.length) {
						$.each(response.guides, function(index, guide) {
							let image    = guide.image ? `<img src="${guide.image}" class="application-guides__item-image" />` : '';
							let category = '';
							let download = '';

							if(Object.keys(guide.category).length) {
								category +=
									`<div class="application-guides__item-category-wrapper">
										<a href="${guide.category.url}">${guide.category.name}</a>
									</div>`;
							}

							if(Object.keys(guide.download).length) {
								download +=
									`<div class="application-guides__download-link-wrapper">
										<a href="${guide.download.url}"
											class="application-guides__download-link"
											target="_blank"
											title="${guide.download.title}"><span class="arrow"></span></a>
									</div>`;
							}

							html += 
								`<div class="application-guides__item">
									<div class="application-guides__item-image-wrapper">
										${image}
									</div>
									<div class="application-guides__item-info-wrapper">
										${category}
										<div class="application-guides__item-title-wrapper">
											<span class="application-guides__item-title">${guide.title}</span>
										</div>
										${download}
									</div>
								</div>`;
						});

						$(obj).removeClass('no-results');
					} else {
						html +=
							`<div class="application-guides__item-no-result"> 
								<p class="application-guides__no-results">${no_results_text}</p>
							</div>`;
						
						$(obj).addClass('no-results');
					}

					$(obj).attr('data-next', response.next);

					if( ! response.next) {
						$(obj).find('.application-guides__load-more-wrapper').addClass('hide');
					} else {
						$(obj).find('.application-guides__load-more-wrapper').removeClass('hide');
					}

					$(obj).find('.load-more').prop('disabled', false);
					$(obj).find('.loader-wrapper .loader').remove();

					if(args.load_more) {
						$(obj).find('.results').append(html);
					} else {
						$(obj).find('.results').html(html);
					}
				});
			}, 150);
		},

		loading : function(obj) {
			let that = this;
			let loader = $(obj).data('loader');

			let loader_html = `<div class="loader"><img src="${loader}" /></div>`;
			
			$(obj).find('.loader-wrapper').html(loader_html);
		}
	};

	if($('div[data-application="application-guides"').length) {
		application_guides.init();
	}
});