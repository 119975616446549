jQuery(document).ready(function($) {
  if ($(window).width() < 1023) {
    $('.main-footer__col').each(function() {
      $(this)
        .find('.widget-title')
        .on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('active');
          $(this)
            .next()
            .slideToggle(); 
        });
    });
  }
});
